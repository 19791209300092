const HeaderStyle = {
    appBarStyles: {
        backgroundColor: "white",
    },
    logo: {
        height: 40,
        margin: 0,
    },
};

export { HeaderStyle };
