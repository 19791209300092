import React, { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useStaticQuery, graphql } from "gatsby";

import { Box, Drawer, Toolbar, List, ListItemText, ListItemButton, Collapse, Button, Chip, Typography } from "@mui/material";

import { Link, useI18next, Trans } from "gatsby-plugin-react-i18next";
const drawerWidth = 320;

const LeftSideBar = ({ subcategories, slug, variant, isOpen, toggleDrawer }) => {
    const { language, languages, defaultLanguage, siteUrl } = useI18next();

    const [open, setOpen] = React.useState({});
    const [categories, setCategories] = React.useState([]);
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [drawerVariant, setDrawerVariant] = React.useState("temporary");

    const { allStrapiTags } = useStaticQuery(graphql`
        query MyQuery {
            allStrapiTags(limit: 10) {
                edges {
                    node {
                        id
                        name
                        slug
                        order
                    }
                }
            }
        }
    `);

    let urlLanguageCode = "";

    if (language != defaultLanguage) {
        urlLanguageCode = `${language}/`;
    }

    console.log(categories, open, drawerOpen)

    useEffect(() => {
        let items = [];
        let opened = {};
        setDrawerOpen(isOpen);
        setDrawerVariant(variant);

        let sortedSubcategories = subcategories
            .map(({ node }) => {
                return {
                    ...node,
                    articles: node.articles.sort(function (a, b) {
                        return b.order - a.order;
                    }),
                };
            })
            .sort(function (a, b) {
                return b.order - a.order;
            });

        sortedSubcategories.forEach(subcategory => {
            if (subcategory.category) {
                if (items.length > 0) {
                    let existing = false;
                    items.forEach(item => {
                        if (item.id == subcategory.category.id) {
                            existing = true;
                            item.subcategories.push(subcategory);
                        }
                    });
                    if (!existing) {
                        items.push({
                            id: subcategory.category.id,
                            locale: subcategory.category.locale,
                            name: subcategory.category.name,
                            slug: subcategory.category.slug,
                            subcategories: [subcategory],
                        });
                    }
                } else {
                    items.push({
                        id: subcategory.category.id,
                        locale: subcategory.category.locale,
                        name: subcategory.category.name,
                        slug: subcategory.category.slug,
                        subcategories: [subcategory],
                    });
                }
            }

            if (subcategory.slug == slug) {
                const openedCategoryId = subcategory.category.id;
                const openedSubcategoryId = subcategory.id;
                opened = {
                    [openedCategoryId]: true,
                    [openedSubcategoryId]: true,
                };
            }

            if (slug && subcategory.category) {
                if (subcategory.category.slug == slug) {
                    const openedCategoryId = subcategory.category.id;
                    opened = {
                        [openedCategoryId]: true,
                    };
                }
            }

            if (slug && subcategory.articles.length > 0) {
                subcategory.articles.forEach(article => {
                    if (article.slug == slug) {
                        const openedCategoryId = subcategory.category.id;
                        const openedSubcategoryId = subcategory.id;
                        opened = {
                            [openedCategoryId]: true,
                            [openedSubcategoryId]: true,
                        };
                    }
                });
            }
        });
        setCategories(items);
        setOpen(opened);
    }, [isOpen]);

    const handleClick = (type, data) => event => {
        const id = data.id;
        let items = [];
        if (type == "category") {
            items = data.subcategories;
        } else if (type == "subcategory") {
            items = data.articles;
        } else if (type == "tag") {
            window.open(`${siteUrl}/${urlLanguageCode}tag/${data}`, "_self");
        }
        if (items.length > 0) {
            setOpen({
                ...open,
                [id]: !open[id],
            });
        }
    };

    const handleClose = (type, data) => event => {};

    return (
        <Drawer
            variant={drawerVariant}
            component="nav"
            open={drawerOpen}
            aria-labelledby="nested-list-subheader"
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
            }}
            onClose={toggleDrawer}
        >
            <Box display={"flex"} flexDirection={"column"} flexGrow={1} p={1}>
                <Box flexGrow={1}>
                    <Toolbar />
                    <Box overflow={"auto"}>
                        <List dense>
                            {categories &&
                                categories.length &&
                                categories.map((category, categoryIndex) => (
                                    <Box key={category.id}>
                                        <ListItemButton selected={category.slug == slug} onClick={handleClick("category", category)}>
                                            <ListItemText primary={category.name} />
                                            {category.subcategories && category.subcategories.length > 0 ? open[category.id] ? <RemoveIcon /> : <AddIcon /> : null}
                                        </ListItemButton>
                                        <Collapse in={open[category.id]} timeout="auto" unmountOnExit>
                                            <List dense component="div" disablePadding>
                                                {category.subcategories &&
                                                    category.subcategories.map((subcategory, subcategoryIndex) => (
                                                        <Box key={subcategory.id}>
                                                            <ListItemButton selected={subcategory.slug == slug} onClick={handleClick("subcategory", subcategory)} sx={{ pl: 4 }}>
                                                                <ListItemText primary={subcategory.name} />
                                                                {subcategory.articles.length > 0 ? open[subcategory.id] ? <RemoveIcon /> : <AddIcon /> : null}
                                                            </ListItemButton>
                                                            <Collapse in={open[subcategory.id]} timeout="auto" unmountOnExit>
                                                                <List dense component="div" disablePadding>
                                                                    {subcategory.articles.map((article, articleIndex) => (
                                                                        <ListItemButton
                                                                            selected={article.slug == slug}
                                                                            component={Link}
                                                                            key={article.id}
                                                                            sx={{ pl: 5 }}
                                                                            to={"/" + article.slug}
                                                                            language={language}
                                                                        >
                                                                            <ListItemText primary={article.title} />
                                                                        </ListItemButton>
                                                                    ))}
                                                                </List>
                                                            </Collapse>
                                                        </Box>
                                                    ))}
                                            </List>
                                        </Collapse>
                                    </Box>
                                ))}
                        </List>
                    </Box>
                </Box>
                <Box p={1}>
                    {allStrapiTags && allStrapiTags.edges && (
                        <Box sx={{ mb: 2 }}>
                            <Typography sx={{ fontSize: 16, pb: 1 }}>
                                <Trans>popular_tags</Trans>
                            </Typography>
                            <Box display={"flex"} sx={{ pb: 2 }}>
                                {allStrapiTags.edges.map(({ node }, tagIndex) => (
                                    <Chip color="primary" key={tagIndex} size={"small"} sx={{ mr: 1 }} label={node.name} variant="outlined" onClick={handleClick("tag", node.slug)} />
                                ))}
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
        </Drawer>
    );
};

export default LeftSideBar;
