// components/seo.js
import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
// import cfgData from "../../websiet-cfg"

const SEO = ({ locale, title, description, keyword, image, slug, children }) => {
    const data = useStaticQuery(graphql`
        query {
            strapiGlobal {
                siteName
                siteDescription
                favicon {
                    localFile {
                        publicURL
                    }
                }
                defaultSeo {
                    id
                    metaTitle
                    metaDescription
                    shareImage {
                        width
                        height
                        localFile {
                            publicURL
                        }
                    }
                }
            }
        }
    `);

    const { siteUrl } = useI18next();
    return (
        <Helmet htmlAttributes={{ language: locale }} titleTemplate={`%s | ${data.strapiGlobal.defaultSeo.metaTitle}`}>
            <title>{title || data.strapiGlobal.siteName}</title>
            <meta property="og:type" content="website" />
            <meta name="og:url" content={`${siteUrl}/${slug}`} />
            <link rel="shortcut icon" href={data.strapiGlobal.favicon.localFile.publicURL} />

            <meta name="description" content={description || data.strapiGlobal.defaultSeo.metaDescription} />
            {/* <meta name="keywords" content={keyword} /> */}
            <link rel="canonical" href={`${siteUrl}${slug}`} />

            {/* <meta
                name="google-site-verification"
                content={cfgData.googleSiteVerification}
            />
            <meta
                name="facebook-domain-verification"
                content={cfgData.facebookDomainVerification}
            />
            <meta property="fb:app_id" content={cfgData.facebookAppId} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@iam_timsmith" /> */}
            <meta name="og:title" content={title} />
            <meta name="og:site_name" content={data.strapiGlobal.siteName} />
            <meta name="og:description" content={description || data.strapiGlobal.siteDescription} />
            <meta name="og:image" content={`${siteUrl}${image || data.strapiGlobal.defaultSeo.shareImage.localFile.publicURL}`} />
            <meta name="og:type" content="website" />
            <meta property="og:image:width" content="1024" />
            <meta property="og:image:height" content="1024" />
            {children}
        </Helmet>
    );
};

SEO.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    keyword: PropTypes.string,
    image: PropTypes.string,
    slug: PropTypes.string,
    children: PropTypes.node,
};

export default SEO;
