import React, { useState, useEffect } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import LanguageIcon from "@mui/icons-material/Language";
import { Link, useI18next, Trans } from "gatsby-plugin-react-i18next";
import Logo from "../images/logo.svg";
import { HeaderStyle } from "../styles/header";
import LeftSideBar from "./LeftSideBar";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { AppBar, Box, Toolbar, IconButton, Menu, Container, Avatar, Tooltip, MenuItem } from "@mui/material";
import SEO from "./Seo";

const Header = ({ props, showMenu }) => {
    const { language, languages, originalPath, siteUrl } = useI18next();

    const [languageEl, setLanguageEl] = React.useState(null);
    const [leftSideBarOpen, setLeftSideBarOpen] = React.useState(false);

    const data = useStaticQuery(graphql`
        query {
            strapiGlobal {
                siteName
                siteDescription
                favicon {
                    localFile {
                        publicURL
                    }
                }
                defaultSeo {
                    id
                    metaTitle
                    metaDescription
                    shareImage {
                        localFile {
                            publicURL
                        }
                    }
                }
            }
        }
    `);

    const handleOpenLangPicker = event => {
        setLanguageEl(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setLanguageEl(false);
    };

    const handleCloseLangPicker = () => {
        setLanguageEl(null);
    };
    const toggleDrawer = () => {
        setLeftSideBarOpen(!leftSideBarOpen);
    };

    return (
        <AppBar position="sticky" style={HeaderStyle.appBarStyles} sx={[{ zIndex: theme => theme.zIndex.drawer + 1 }, { boxShadow: 1 }]}>
            <Container disableGutters maxWidth={false}>
                <SEO locale={props.pageContext.locale} />
                <Toolbar>
                    {showMenu && (
                        <IconButton
                            size="large"
                            edge="start"
                            aria-label="menu"
                            sx={{ ml: 1, display: { xs: "flex", md: "none" } }}
                            onClick={() => {
                                setLeftSideBarOpen(true);
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                    {props && (
                        <Box sx={{ ml: 1, display: { xs: "flex", md: "none" } }}>
                            <LeftSideBar
                                subcategories={props.data.subcategories ? props.data.subcategories.edges : []}
                                slug={props.pageContext.slug}
                                variant={"temporary"}
                                isOpen={leftSideBarOpen}
                                toggleDrawer={toggleDrawer}
                            />
                        </Box>
                    )}
                    <Box sx={{ display: "flex", mx: 1 }} component={Link} to={"/"} language={language}>
                        <Box component={"img"} sx={{ flexGrow: 1 }} src={Logo} style={HeaderStyle.logo} alt="Logo" />
                    </Box>
                    <Box sx={{ flexGrow: 1 }}></Box>
                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenLangPicker} sx={{ p: 0 }}>
                                <Avatar sx={{ bgcolor: "transparent", color: "black" }}>
                                    <LanguageIcon />
                                </Avatar>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: "45px" }}
                            id="menu-appbar"
                            anchorEl={languageEl}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={Boolean(languageEl)}
                            onClose={handleCloseLangPicker}
                        >
                            {languages.map(lng => (
                                <MenuItem component={Link} to={originalPath} language={lng} key={lng} onClick={handleCloseNavMenu}>
                                    <Trans>{lng}</Trans>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default Header;
